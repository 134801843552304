import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import LayoutPublic from "../layouts/Public.vue";
import LayoutUser from "../layouts/User.vue";

import {
  routes as userRoutes,
  middleware as userMiddleware,
} from "@/views/user/Routes";

const routes = [
  {
    path: "/verify",
    name: "Verify",
    component: () => import("../views/public/Verify"),
    //redirect: (to) => {
    //return '/user/';
    //},
  },
  {
    path: "/",
    name: "",
    component: LayoutPublic,
    children: [
      {
        path: "",
        name: "PublicHome",
        component: () => import("../views/public/Home"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/contact-us",
        name: "PublicContactUs",
        component: () => import("../views/public/Contactus"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/faq",
        name: "PublicFaq",
        component: () => import("../views/public/Faq"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
      {
        path: "/terms",
        name: "PublicTerms",
        component: () => import("../views/public/Terms"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/about-us",
        name: "PublicAboutUs",
        component: () => import("../views/public/Aboutus"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/blogs",
        name: "PublicBlogs",
        component: () => import("../views/public/Blog"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/store",
        name: "PublicStore",
        component: () => import("../views/public/Store"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
      {
        path: "/store/:id",
        name: "PublicStoreSingel",
        component: () => import("../views/public/SingelStore"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
    ],
  },

  ...userRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => {
      /**
       * خیلی مهم:
       * میدلور هر لایه حساب کاربری باید به طوری جداگانه فراخوانی شود
       * و در زیر قرار بگیرد
       *
       **/
      // console.log(record);

      userMiddleware(record, next, store.state.userIsAuthenticated);
    })
    /* eslint-disable */
  ) {
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;

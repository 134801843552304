<template>
  <div class="footer-bar">
    <router-link to="/user/exchange" class="footer-bar-a">
      <i class="bi bi-arrow-down-up"></i>

      <span class="footer-bar-span">تبادل</span>
    </router-link>
    <router-link to="/user/wallet" class="footer-bar-a">
      <i class="bi bi-wallet"></i>
      <span class="footer-bar-span">کیف پول</span>
    </router-link>

    <router-link to="/user/profile" class="footer-bar-a">
      <i class="bi bi-person"></i>

      <span class="footer-bar-span">پروفایل</span>
    </router-link>

    <router-link to="/user/widthraw" class="footer-bar-a">
      <i class="bi bi-wallet"></i>
      <span class="footer-bar-span">برداشت</span>
    </router-link>

    <a @click="logout()" class="footer-bar-a">
      <i class="bi bi-door-open"></i>
      <span class="footer-bar-span"> خروج</span>
    </a>

    <!-- :to="{ name: 'PanelUser-Logout' }" -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    openModalLayerOPtions() {
      $("#modal-layer-options").modal();
    },

    handleConfirmationLogout() {
      this.$router.push({ name: "PanelUser-Logout" });
    },
    logout() {
      Swal.fire({
        title: "آیا مطمین هستید ؟",
        text: "آیا میخواین از حساب کاربری خود خارج شوید ؟",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "بله",
        cancelButtonText: "لغو",
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleConfirmationLogout();
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.footer-bar {
  position: fixed;

  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
  background-color: #1e1f25;
  /*box-shadow: 0 -5px 10px 0 rgb(0 0 0 / 6%);*/
  border-top: 1px #9a59017d solid;
  min-height: 60px;
  display: flex;
  text-align: center;
  transition: all 350ms ease;
}

.footer-bar-a {
  color: #fff !important;
  padding-top: 12px;
  position: relative;
  flex: 1 1 auto;
}

.footer-bar-span {
  position: relative;
  z-index: 2;
  display: block;
  font-size: 10px;
  font-weight: 500 !important;
  margin-top: -3px;
  opacity: 0.7;
  font-family: "Vazirmatn";
  font-weight: bold;
}

.footer-bar-i {
  font-size: 18px;
  position: relative;
  z-index: 2;
}
</style>

<template>
  <NavBar @toggle-blur="toggleBlur" />
  <div>
    <div :class="{ blurred: isBodyBlurred }"></div>
    <router-view :key="$route.fullPath" />
  </div>
  <Footer />
</template>

<script>
import NavBar from "@/components/public/NavBar.vue";
import Footer from "@/components/public/Footer.vue";
import { checkAuthUser } from "@/utils/checkAuth";

export default {
  components: { NavBar, Footer },
  data() {
    return {
      isBodyBlurred: false,
    };
  },
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    toggleBlur() {
      this.isBodyBlurred = !this.isBodyBlurred;
    },
    async checkUserLogin() {
      console.log("Checking");
      this.$store.commit("readUserToken");
      if (this.$store.state.userToken) {
        const authResponse = await checkAuthUser(
          "/v2/users/auth/validate",
          this.$store.state.userToken
        );
        console.log(authResponse.status);

        this.sleep(100).then(() => {
          if (authResponse.status) {
            this.checkLogin = true;
            this.$store.state.userDetails = authResponse.data;
            this.$store.commit("setUserToken", this.$store.state.userToken);
          } else {
            this.$store.commit("clearUserToken");
          }
        });
      } else {
        this.$store.commit("clearUserToken");
      }
    },
  },
  mounted() {
    this.checkUserLogin();
  },
};
</script>

<style scoped>
.sd {
  background-color: red;
}
.blurred {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  position: absolute;
  z-index: 5;
}
</style>

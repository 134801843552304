<template>
  <Navbar />
  <div><router-view :key="$route.fullPath" /></div>
  <FooterMenu v-if="userAgentDetails.screenType === 'small'" />
  <Footer />
</template>
  
  <script>
import Navbar from "@/components/user/Navbar.vue";
import Footer from "@/components/user/Footer.vue";
import FooterMenu from "@/components/user/FooterMenu.vue";

export default {
  components: { Navbar, Footer, FooterMenu },
  data() {
    return {
      userAgentDetails: {},
    };
  },

  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    handleUserAgentDetails() {
      this.userAgentDetails = this.$root.getUserAgentDetails();
    },
  },

  created() {
    window.addEventListener("resize", this.handleUserAgentDetails);
    this.handleUserAgentDetails();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleUserAgentDetails);
  },

  mounted() {
    this.userAgentDetails = this.$root.getUserAgentDetails();
    this.checkMounted = true;
  },
};
</script>
  
  <style lang="scss" scoped>
</style>
<template>
  <div class="row">
    <div class="col-12" style="
        box-shadow: inset 0 0 0 99999px rgb(22 22 25 / 85%);
        height: 140px;
        width: 100%;
        background-image: url('/assets/media/images/webp/glowing-blue-wave-pattern-reflects-futuristic-fantasy-generated-by-ai.webp');
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;
      ">
      <div class="row">
        <div class="col-10">
          <div class="row p-4" style="direction: rtl; text-align: right">
            <div class="col-6">
              <h3 class="mt-4" style="color: var(--bs-gold-light); font-size: 21px">
                همین حالا سرمایه‌گذاری را شروع کنید
              </h3>
            </div>

            <div class="col-6">
              <div>
                <form class="mini-form" style="width: 70%">
                  <input type="text" style="border-radius: 14px" class="form-control form-control-sm mini-form__input" placeholder="شماره موبایل خود را وارد کنید" autocomplete="off" />
                  <input type="submit" style="
                      background-color: var(--bs-gold-light);
                      border-radius: 14px;
                      color: var(--bs-dark);
                      border-color: unset;
                    " class="btn btn-warning btn-sm mini-form__submit" value="ارسال" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer style="
    background-image: url(https://grownexia.ir/wp-content/uploads/2024/06/image.png);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 99999px rgb(22 22 25 / 91%);

  " class="footer">
    <div class="row" style="direction: rtl;
    text-align: center;
    justify-content: center;
    ">

      <div class="col-12">
        <div class="row" style="justify-content: center;">

          <div class="col-8">
            <div class="row mt-4">
              <div class="col-2">
                <img src="/assets/logo/i.png" class="img-fluid" style="height:56px" alt="" />

              </div>
              <div class="col-8">

                <p class="" style="
            font-size: 12px;
            direction: rtl;
            text-align: right;
            ">

                  همراه زر بستری است برای خرید و فروش طلا برای همه اقشار با هر سطحی از دارایی و به قیمت منصفانه؛ و در این راه همواره تمام تلاش خود را نموده‌ایم تا دسترسی امن را برای عموم مردم فراهم سازد. از دریافت مجوز از اتحادیه صنف فروشندگان و سازندگان طلا، جواهر، نقره و سکه تهران تا مجوز راه‌اندازی سامانه معاملات آنلاین طلای آب‌شده.



                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class=" col-10">

        <div class="row" style="">
          <div class="col-12">

            <div class="row p-5" style="text-align: right;">

              <div class="col-6">

                <h4 style="font-size: 21px;">
                  اطلاعات ارتباطی

                </h4>
                <ul class="mt-4" style="list-style-type: none">
                  <li>
                    <i class="bi bi-pin-map mx-1" style="color: var(--bs-gold-light)"></i>
                    آدرس : قزوین محمودآبادنمونه خیابان امام خمینی جنب اداره
                    پست طلا و جواهری میلاد
                  </li>
                  <li>
                    <i class="bi bi-phone mx-1" style="color: var(--bs-gold-light)"></i>
                    تلفن:

                    <a href="tel:0289145455" style="display: inline-block">028-9145455</a>
                  </li>
                  <li>
                    <i class="bi bi-phone mx-1" style="color: var(--bs-gold-light)"></i>
                    تلفن:

                    <a href="tel:02133265576" style="display: inline-block">021-33265576</a>
                  </li>
                  <li>
                    <i class="bi bi-envelope-open mx-1" style="color: var(--bs-gold-light)"></i>
                    ایمیل: info@hamrahzar.com
                  </li>

                  <li>
                    <i class="bi bi-clock mx-1" style="color: var(--bs-gold-light)"></i>
                    شنبه تا چهارشنبه ۱۰ تا ۱۸ پنجشنبه ۱۰ تا ۱۴ (به جز ایام
                    تعطیل)
                  </li>
                </ul>

              </div>


              <div class="col-2">
                <h4 style="font-size: 21px;">
                  دسترسی سریع

                </h4>
                <ul class="mt-4" style="list-style-type: none">
                  <li>
                    درباره‌ما
                  </li>
                  <li>
                    تماس با ما
                  </li>
                  <li>
                    قوانین و مقررات
                  </li>

                </ul>

              </div>

              <div class="col-4">
                <h4 style="font-size: 21px;">
                  <br />
                  <br />
                </h4>

                <div class="row">
                  <div class="col-4">

                    <div class="p-2" style="
                    text-align: center;
                    background-color:#efb1000a ;
                    border-radius: 7px;
                    ">

                      <img style="width: 100px;" src="https://grownexia.ir/wp-content/uploads/2024/06/namad.png">

                    </div>

                  </div>
                  <div class="col-4">

                    <div class="p-2" style="
text-align: center;
background-color:#efb1000a ;
border-radius: 7px;
">

                      <img style="width: 72px;" src="https://grownexia.ir/wp-content/uploads/2024/06/samandehi.png">

                    </div>

                  </div>
                  <div class="col-4">

                    <div class="p-2" style="
text-align: center;
background-color:#efb1000a ;
border-radius: 7px;
">

                      <img style="width: 63px;" src="https://grownexia.ir/wp-content/uploads/2024/06/etahi.png">

                    </div>

                  </div>
                </div>


              </div>


            </div>

          </div>
        </div>

      </div>
    </div>

  </footer>
</template>

<script>
import PrTextLogo from "@/components/custom/PrTextLogo.vue";

export default {
  components: { PrTextLogo },
};
</script>

<style lang="css" scoped>
a {
  color: #fff;
}
</style>

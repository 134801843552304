export const routes = (parentName, pathRoot, groupName) => {
  return [
    {
      name: `${parentName}-Login`,
      path: pathRoot + "auth/login",
      meta: {
        requiresAuthReverse: true,
        group: groupName,
      },
      component: () => import("./Login.vue"),
    },
    {
      name: `${parentName}-Logout`,
      path: pathRoot + "logout",
      component: () => import("./Logout.vue"),
    },
  ];
};

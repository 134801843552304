import axios from "axios";
import { createStore } from "vuex";

export default createStore({
  state: {
    userDetails: {
      firstName: "",
      lastName: "",
    },
    userIsAuthenticated: false,
    userToken: "",
    userTokenStorageKeyName: "8bfa55fc69baee1047a4682d8da2bbd9UserToken",
    userRoutesGroupName: "user",
    userRoutesPathRoot: "/user/",
    userRoutesParentName: "PanelUser",

    // ------- API base url ------- //
    apiBaseUrlKeyName: "8bfa55fc69baee1047a4682d8da2bbd9ApiBaseUrl",
    apiBaseUrl: "",
    // ------- ------- //
  },
  getters: {},
  mutations: {
    // +++++++ USER STORE +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ //
    readUserToken(state) {
      let token = localStorage.getItem(state.userTokenStorageKeyName);
      state.userToken = token;
    },

    setUserToken(state, userToken) {
      if (userToken) {
        state.userIsAuthenticated = true;
        state.userToken = userToken;
        localStorage.setItem(state.userTokenStorageKeyName, userToken);
        axios.defaults.headers.common["x-token-user"] = userToken;
      } else {
        state.userIsAuthenticated = false;
        state.userToken = "";
        localStorage.removeItem(state.userTokenStorageKeyName);
        axios.defaults.headers.common["x-token-user"] = "";
      }
    },

    clearUserToken(state) {
      state.userIsAuthenticated = false;
      state.userToken = "";
      localStorage.removeItem(state.userTokenStorageKeyName);
      axios.defaults.headers.common["x-token-user"] = "";
    },
    setUserDetails(state, userDetails) {
      state.userDetails.firstName = userDetails.firstName;
      state.userDetails.lastName = userDetails.lastName;
    },
    // ####### END USER STORE ####### ####### ####### ####### ####### ####### ####### //
  },
  actions: {
    /** ------- USER ------- ------- ------- ------- ------- ------- ------- */
    execReadUserToken({ commit, state }) {
      commit("readUserToken");
    },

    execSetUserToken({ commit, state }) {
      commit("setUserToken");
    },

    execClearUserToken({ commit }) {
      commit("clearUserToken");
    },

    /** ------- END USER ------- ------- ------- ------- ------- ------- ------- */
  },
  modules: {},
});
